import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Peter Kim #OpenToWork</h1>

        <ul className="footer__list">
          <li>
            <a href="#about" className="footer__link">About</a>
            <a href="#skills" className="footer__link">Skills</a>
            <a href="#qualifications" className="footer__link">Qualifications</a>
          </li>
        </ul>

        <div className="footer__social">
          <a href="https://www.linkedin.com/in/peter-kim-a8bb3059/" className="footer__social-link" target="_blank" rel="noreferrer" aria-label="My LinkedIn Page">
            <i className="bx bxl-linkedin-square"></i>
          </a>
          <a href="https://github.com/pdurkim" className="footer__social-link" target="_blank" rel="noreferrer" aria-label="My Github Page">
            <i className="bx bxl-github"></i>
          </a>
          <a href="https://www.facebook.com/peyterkim" className="footer__social-link" target="_blank" rel="noreferrer" aria-label="My Facebook Page">
            <i className="bx bxl-facebook"></i>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer